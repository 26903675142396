.wbcontainer{
    position: fixed;
    padding: 20px;
    bottom: 55px;
    animation: fade-up 1000ms forwards;
    animation-delay: 1s;
    
  }
  
  @keyframes fade-up {
    100%{
      bottom: 55px;
      opacity: 0.8;
    }
  }
  
  .wbcontainer .wb-btn{
    display: inline-block;
    opacity: 1;
    transition: all 400ms;
  }
  
  .wbcontainer .wb-btn:hover {
    transform: scale(1.2);
  }
  
  .wbcontainer .wb-btn:hover+span{
    transform: rotateZ(0deg) translateX(0px);
    opacity: 1;
  }
  
  .wbcontainer span{
    position: absolute;
    top:0;
    left: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    opacity: 0;
    background: linear-gradient(to right,red, green );
    background-clip: text;
    color: transparent;
    transform: rotateZ(50deg) translateX(30px);
    transition: all 400ms;
  }
  
  #btnScrollTop {
    display: none;
    position: fixed;
    bottom: 80px;
    right: 10px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background: #198754;
    opacity: 1;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 30px;
  }