#slider{
    display: flex;
    flex-direction: row;
    overflow: hidden;
}


#slider img{
    width:100%;
    animation: 20s slider infinite;
}

@keyframes slider {
    0%{
        transform: translateX(0%);
    }
    25%{
        transform: translateX(0%);
    }
    50%{
        transform: translateX(-100%);
    }
    75%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0%);
    }
}