/*sliding navigation CSS Starts Here*/

@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 0 0;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    /* position: absolute;
    top: 0;
    width: 10px;
    height: 100%; */
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(0, 255, 0, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(0, 255, 0, 0), white);
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 15s slide infinite linear;
  }
  
  .logos-slide span {
    height: 50px;
    margin: 0 500px;
  }
  
  /*sliding navigation CSS Ends Here*/